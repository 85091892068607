<template>
  <div class="ui-accordion" :class="{ visible: visible }">
    <button type="button" class="ui-accordion__header" @click="toggleVisibility">
      <div class="header-text">
        {{ header }}
      </div>
      <div class="header-icon">
        <i class="fas fa-angle-down"></i>
      </div>
    </button>
    <transition name="collapse">
      <template v-if="visible">
        <div class="ui-accordion__body">
          <slot />
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
    },
    body: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    toggleVisibility() {
      return (this.visible = !this.visible);
    },
  },
};
</script>
