<template>
  <div id="site-footer">
    <div class="top-menu">
      <div class="container">
        <ul>
          <li class="menu-item">
            <span @click="onClick('terms_of_use')">{{ $__t('{terms-of-use-form}.terms_of_use.label') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('privacy_policy')">{{ $__t('개인정보 처리방침') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('faq')">{{ $__t('자주묻는 질문') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('introduce_service')">{{ $__t('서비스 소개서') }}</span>
          </li>
          <!-- <li class="menu-item">
            <span @click="onClick('guide')">{{ $__t('서비스 가이드') }}</span>
          </li> -->
          <!-- <li class="menu-item">
            <span @click="onClick('notice')">{{ $__t('공지사항') }}</span>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="contents">
      <div class="container">
        <!--왼쪽-->
        <div class="cs-area">
          <div class="primary">
            <span class="title">{{ $__t('C/S') }}</span>
            <br />
            <span class="phone">{{ $__t('__전화번호') }}</span>
            <!-- <div class="message">{{ $__t('주말에는 카카오톡, 채널톡을 이용한 단순 문의만 가능합니다.') }}</div> -->
          </div>
          <div class="secondary">
            <div class="title">{{ $__t('운영시간') }}</div>
            <div class="text-timetable">
              <p>{{ $__t('평일') }} <span>10:00 ~ 17:30</span></p>
              <p>{{ $__t('점심시간') }} <span>12:00 ~ 13:30</span> ({{ $__t('공휴일, 임시공휴일 휴무') }})</p>
            </div>
          </div>
          <ul class="email">
              <li>
                <div class="title">
                  {{ $__t('C/S') }} 
                </div>
                <div class="text-email">support@lugstay.com</div>
              </li>
              <li>
                <div class="title">
                  {{ $__t('비즈니스제휴') }} 
                </div>
                <div class="text-email">biz@lugstay.com</div>
              </li>
            </ul>
          <div class="link-area">
            <ul>
              <!-- <li @click="onClick('facebook')">
                <img src="/img/sns-icon-facebook.png" />
              </li> -->
              <li @click="onClick('instagram')">
                <img src="/img/sns-icon-instagram-color.png" />
              </li>
              <li @click="onClick('naver_blog')">
                <img src="/img/sns-icon-naver-blog-color.png" />
              </li>
              <!-- <li @click="onClick('youtube')">
                <img src="/img/sns-icon-youtube.png" />
              </li> -->
            </ul>
          </div>
        </div>
        <!--오른쪽-->
        <div class="info-area">
          <div class="misc">
            <ul class="infos">
              <li class="infos-detail">
                {{ $__t('__회사명') }} <span>|</span> {{ $__t('사업자 등록번호') }} : {{ $__t('__사업자 등록번호') }} <span>|</span> {{ $__t('대표') }} : {{ $__t('__대표') }}
              </li>
              <li class="infos-detail">{{ $__t('통신판매업 신고번호') }} : {{ $__t('__통신판매업 신고번호') }}</li>
              <li class="infos-detail">{{ $__t('__본사') }}</li>
            </ul>
            <div class="related-sites">
              <div class="select-form" :data-value="value" :data-list="list">
                <div class="selector" @click="toggle()">
                  <div class="label">
                    <span>{{ $__t(value) }}</span>
                    <span><img src="/img/icon-add.svg" /></span>
                  </div>
                  <div :class="{ hidden : !visible, visible }">
                    <ul>
                      <li :class="{ current : item === value }" v-for="item in list" @click="select(item)">
                        {{ $__t(item.label) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright">
            <span>©2018 BLUEWHALE COMPANY INC. All Right Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    clickedFrom() {
      if (this.$route.name === 'Home') return 'home';
      else return null;
    }

    // isForIosAppInspection(){
    // 	if(this.wni.isNative){
    // 		const deviceInfo = utils.parseJSON(this.$store.state.config.userHeaders["x-device-info"]);

    // 		return (deviceInfo.os_type === 'ios') && (deviceInfo.app_version ===  "1.2.29")
    // 	}

    // 	return false;
    // }
  },
  data() {
    return {
      value: '관련 사이트',
      list: [
        {
          "label": "유후",
          "value": "uhoo",
          'url': 'https://landing.u-hoo.com'
        },
        {
          "label": "유후 블로그",
          "value": "uhooBlog",
          'url': 'https://blog.naver.com/uhoo_official/',
        },
        {
          "label": "유후 인스타그램",
          "value": "uhooInstagram",
          'url': 'https://www.instagram.com/uhoo.official/?igsh=MWZhZ3MwM2podGFseg%3D%3D',
        },
        {
          "label": "블루웨일컴퍼니",
          "value": "BluewhaleCompany",
          'url': 'https://www.bwc.co.kr',
        }
      ],
      visible: false
    };
  },
  methods: {
    onClick(param) {
      let routeName = '';

      switch (param) {
        case 'privacy_policy':
          // routeName = 'CsPrivacyPolicy';
          // if (this.$route.name !== routeName) {
          //   if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
          //   this.$router.push({ name: 'CsPrivacyPolicy' }).catch((err) => {});
          // }
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://broad-slash-0eb.notion.site/b81f48636e8e4cd5b4d98bd31d65c000?pvs=4'
            });
          } else {
            window.open('https://broad-slash-0eb.notion.site/b81f48636e8e4cd5b4d98bd31d65c000?pvs=4');
          }
          break;
        case 'terms_of_use':
          // routeName = 'CsTermsOfUse';
          // if (this.$route.name !== routeName) {
          //   if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
          //   this.$router.push({ name: 'CsTermsOfUse' }).catch((err) => {});
          // }
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://broad-slash-0eb.notion.site/1c30e649243742c1a72b1daaf553d826?pvs=4'
            });
          } else {
            window.open('https://broad-slash-0eb.notion.site/1c30e649243742c1a72b1daaf553d826?pvs=4');
          }
          break;
        case 'guide':
          routeName = 'CsGuide';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
            this.$router.push({ name: 'CsGuide' }).catch((err) => {});
          }
          break;
        case 'notice':
          routeName = 'CsNotices';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);

            console.log( "#notice" );
            this.$router.push({ name: 'CsNotices' }).catch((err) => {
              console.error(err);
            });
          }
          break;
        case 'faq':
          // routeName = 'CsFaq';
          // if (this.$route.name !== routeName) {
          //   if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
          //   this.$router.push({ name: 'CsFaq' }).catch((err) => {});
          // }
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://broad-slash-0eb.notion.site/FAQ-6a1b05aa838048548b96067a2acf3405'
            });
          } else {
            window.open('https://broad-slash-0eb.notion.site/FAQ-6a1b05aa838048548b96067a2acf3405');
          }
          break;
        case 'introduce_service': {
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://cdn.u-hoo.com/uploaded/럭스테이(Lugstay)_서비스소개서.pdf'
            });
          } else {
            window.open('https://cdn.u-hoo.com/uploaded/럭스테이(Lugstay)_서비스소개서.pdf');
          }
          break;
        }
        case 'facebook':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.facebook.com/LugStay.official/'
            });
          } else {
            window.open('https://www.facebook.com/LugStay.official/');
          }
          break;
        case 'instagram':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.instagram.com/lugstay.official/'
            });
          } else {
            window.open('https://www.instagram.com/lugstay.official/');
          }
          break;
        case 'naver_blog':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://blog.naver.com/lugstay'
            });
          } else {
            window.open('https://blog.naver.com/lugstay');
          }
          break;
        case 'youtube': {
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured'
            });
          } else {
            window.open('https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured');
          }
          break;
        }
        case 'business_aliance':
          location.href = 'mailto:ljk@lugstay.com';
          break;
      }
    },

    toggle() {
				this.visible = !this.visible;
    },
    select(option) {
      this.value = option.label;
      window.location.href = option.url;
    }
  }
};
</script>
