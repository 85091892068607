<template>
  <div class="ui-card-image">
    <div class="container">
      <div v-if="target.img" class="ui-card-image__image-area">
        <progressive-img :src="target.img" class="image" />
      </div>
      <div class="ui-card-image__text-area">
        <transition name="fade">
          <div v-if="target.header" v-html="target.header" class="header">
          </div>
        </transition>
        <transition name="fade">
          <div v-if="target.body" v-html="target.body" class="body">
          </div>
        </transition>
        <div class="footer">
          <transition name="fade">
            <div v-if="target.label" class="label">
              {{ target.label }}
            </div>
          </transition>
          <div v-if="items.length > 1" class="pager">
            <button v-button type="button" @click="forward">
              <i class="fas fa-angle-left"></i>
            </button>
            <button v-button type="button" @click="after">
              <i class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentIndex: 0,
    };
  },

  watch: {
    target() {
      this.$forceUpdate();
    },
  },

  computed: {
    target() {
      if (this.items.length > this.currentIndex) {
        return this.items[this.currentIndex];
      }

      return {};
    },
  },

  methods: {
    forward() {
      if (this.currentIndex === 0) {
        return (this.currentIndex = this.items.length - 1);
      }

      return (this.currentIndex -= 1);
    },

    after() {
      if (this.currentIndex === this.items.length - 1) {
        return (this.currentIndex = 0);
      }

      return (this.currentIndex += 1);
    },
  },
};
</script>
