<template>
  <ui-form ref="form" :formData="formData" @submit="onSubmit" class="host-apply-form">
    <h2 class="host-apply-form__header" v-html="FORM_TEXT.HEADER"></h2>
    <!-- <p class="host-apply-form__description">{{ FORM_TEXT.DESCRIPTION }}</p> -->
    <fieldset class="host-apply-form__fieldset">
      <input
        type="text"
        id="store_name"
        v-model.trim="formData.store_name.value"
        :placeholder="formData.store_name.placeholder"
        class="host-apply-form__text-input"
        :class="{ error: formData.store_name.error }"
        @input="formData.store_name.error = ''"
      />
      <input
        id="store_phone"
        type="tel"
        v-model.trim="formData.store_phone.value"
        :placeholder="formData.store_phone.placeholder"
        class="host-apply-form__text-input"
        :class="{ error: formData.store_phone.error }"
        @input="formData.store_phone.error = ''"
      />
    </fieldset>
    <!-- <fieldset class="host-apply-form__fieldset" :class="{ error: formData.is_cctv.error }">
      <legend class="host-apply-form__legend">
        {{ formData.is_cctv.legend }}
      </legend>
      <input
        type="radio"
        id="is_cctv_yes"
        :value="1"
        v-model="formData.is_cctv.value"
        @input="formData.is_cctv.error = ''"
        class="host-apply-form__radio-input"
      />
      <label for="is_cctv_yes" class="host-apply-form__label">예</label>
      <input
        type="radio"
        id="is_cctv_no"
        :value="0"
        v-model="formData.is_cctv.value"
        @input="formData.is_cctv.error = ''"
        class="host-apply-form__radio-input"
      />
      <label for="is_cctv_no" class="host-apply-form__label">아니오</label>
    </fieldset> -->
    <!-- <fieldset class="host-apply-form__fieldset" :class="{ error: formData.is_staff.error }">
      <legend class="host-apply-form__legend">
        {{ formData.is_staff.legend }}
      </legend>
      <input
        type="radio"
        id="is_staff_yes"
        :value="1"
        v-model="formData.is_staff.value"
        @input="formData.is_staff.error = ''"
        class="host-apply-form__radio-input"
      />
      <label for="is_staff_yes" class="host-apply-form__label">예</label>
      <input
        type="radio"
        id="is_staff_no"
        :value="0"
        v-model="formData.is_staff.value"
        @input="formData.is_staff.error = ''"
        class="host-apply-form__radio-input"
      />
      <label for="is_staff_no" class="host-apply-form__label">아니오</label>
    </fieldset> -->
    <fieldset class="host-apply-form__fieldset terms-agreed">
      <!-- <ui-checkbox
        id="terms_agreed"
        v-model="formData.terms_agreed.value"
        @input="formData.terms_agreed.error = ''"
        :label="formData.terms_agreed.label"
        class="host-apply-form__check-box"
        :error="formData.terms_agreed.error"
      /> -->
      <button
        type="button"
        @click.prevent="$refs.form.submit"
        class="host-apply-form__submit-button ui-submit-button activated"
        :style="{ background: '#fae300' }"
      >
        <img src="/img/icon-kakaotalk.svg" class="button-icon" />
        <span class="ui-submit-button__text" :style="{ color: '#391b1b' }">카카오톡으로 안내받기</span>
      </button>
    </fieldset>
  </ui-form>
</template>

<script>
const FORM_TEXT = {
  HEADER: '고민하지 말고 <br/> 부담 없이 시작하세요',
  DESCRIPTION: '카카오 채널을 통해 등록 안내드리겠습니다.',
  PLACEHOLDER: {
    STORE_NAME: '상점명',
    STORE_PHONE: '휴대전화 번호 (숫자만 입력해주세요)',
  },
  LEGEND: {
    IS_CCTV: '상점 내 CCTV 가 있습니까?',
    IS_STAFF: '상점 내 상주 인원이 있습니까?',
  },
  LABEL: {
    TERMS_AGREED: '개인정보 수집 이용 동의',
  },
  SUBMIT: '내 공간 상담하기',
};

export default {
  data() {
    return {
      formData: {
        store_name: {
          placeholder: FORM_TEXT.PLACEHOLDER.STORE_NAME,
          value: '',
          error: '',
          validation: [{ type: 'required' }],
        },
        store_phone: {
          placeholder: FORM_TEXT.PLACEHOLDER.STORE_PHONE,
          value: '',
          error: '',
          validation: [{ type: 'required' }, { type: 'number' }],
        },
        // is_cctv: {
        //   legend: FORM_TEXT.LEGEND.IS_CCTV,
        //   value: '',
        //   error: '',
        //   validation: [{ type: 'required' }],
        // },
        // is_staff: {
        //   legend: FORM_TEXT.LEGEND.IS_STAFF,
        //   value: '',
        //   error: '',
        //   validation: [{ type: 'required' }],
        // },
        // terms_agreed: {
        //   label: FORM_TEXT.LABEL.TERMS_AGREED,
        //   value: false,
        //   error: '',
        //   validation: [{ type: 'required' }],
        // },
      },

      FORM_TEXT,
    };
  },

  methods: {
    async onSubmit(values) {
      const { store_name, store_phone, is_cctv, is_staff, terms_agreed } = values;
      try {
        const payload = {
          store_name: store_name,
          contact_phone: store_phone,
          cctv: parseInt(is_cctv),
          staff: parseInt(is_staff),
          agreed: terms_agreed === true ? 1 : 0,
        };

        const response = await this.axios.post(this.$store.state.config.apiURL + '/v2/acquisition/contact', payload);

        if (response.data?.data?.talk_flag) {
          this.$store.commit('alert/ADD_ITEM', {
            message: '상담 신청이 접수되었습니다. 감사합니다.',
            status: 'success',
          });
        }

        this.$analytics.logTrack('track', 'ApplicationformSubmit');
        this.$emit('submit');
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        await this.$utils.wait(100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.host-apply-form {
  padding: unit(16) unit(32);

  &__header {
    margin-top: unit(20);
    font-weight: bold;
    font-size: unit(24);
    line-height: 1.35;
    letter-spacing: -0.04em;
  }

  &__description {
    margin-top: 11px;
    font-size: unit(14);
    line-height: 1.5;
    letter-spacing: -0.04em;
  }

  &__fieldset {
    margin: 0;
    border: 0;
    padding: 0;
    margin-top: unit(24);

    & > * + * {
      margin-top: unit(12);
    }

    &.terms-agreed {
      // margin-top: unit(32);
    }

    &.error {
      * {
        color: #ff5555;
      }
    }
  }

  &__text-input {
    font-size: unit(14);
    padding: unit(12);
    line-height: 1.5;
    letter-spacing: -0.04em;
    border: 1px solid #e1e4e6;
    border-radius: unit(10);

    &:focus {
      border-color: #008fff;
    }

    &::placeholder {
      color: #a9afb3;
    }

    &.error {
      border-color: #ff5555;
      color: #ff5555;

      &::placeholder {
        color: #ff5555;
      }
    }
  }

  &__radio-input {
    font-size: unit(14);
    line-height: 1.5;
    letter-spacing: -0.04em;
    border: 1px solid #e1e4e6;
    border-radius: unit(10);
    display: inline-block;
    width: unit(12);
    height: unit(12);
    border-radius: 50%;
    margin-right: unit(6);
    margin-left: unit(60);

    &:checked {
      &::after {
        content: '';
        @include fsa;
        border-radius: 50%;
        background: #008fff;
        transform: scale(0.85);
      }
    }

    &:first-of-type {
      margin: 0;
      margin-right: unit(6);
    }

    &:focus {
      border-color: $color-primary;
    }

    &::placeholder {
      color: #a9afb3;
    }

    &.error {
      border-color: #ff5555;
      color: #ff5555;

      &::placeholder {
        color: #ff5555;
      }
    }
  }

  &__legend {
    font-size: unit(14);
    line-height: 1.5;
    color: #a9afb3;
  }

  &__submit-button {
    margin-top: unit(12);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: unit(4);

    .button-icon {
      width: unit(24);
      height: unit(24);
      object-fit: contain;
    }

    &:hover {
      cursor: pointer;
      background-color: #0073cd;
    }
  }

  &__label {
    font-size: unit(14);
    line-height: 1.5;
    color: #a9afb3;
  }
}
</style>
