<template>
  <div class="ui-tab-image">
    <div class="image-area">
      <progressive-img :src="items[currentIndex]['img']" class="image" />
    </div>
    <div class="text-area">
      <div class="header">
        <ul class="tab-list">
          <li class="tab-item" v-for="(item, index) in items" :key="item.tab" :class="{activated: currentIndex === index}" v-button @click="currentIndex = index">
            <span v-if="currentIndex === index" class="underline-skewed"
              ><span class="text">{{ item.tab }}</span></span
            >
            <span v-else>{{ item.tab }}</span>
          </li>
        </ul>
      </div>
      <div class="body">
        <p>{{ items[currentIndex]["text"] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentIndex: 0,
    };
  },
};
</script>
