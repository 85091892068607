<template>
  <div class="page" id="host-landing-page">
    <div id="float-header" class="header">
      <div class="container">
        <div class="image-area">
          <img src="/img/logo-mint-new.svg" />
        </div>

        <div class="gnb-area">
          <ul>
            <li @click="onNavigate('Home')">{{ $__t('고객') }}</li>
            <li class="test-test" @click="onNavigate('HostLanding')">{{ $__t('호스트') }}</li>
            <li @click="downloadFile()">{{ $__t('서비스 소개서') }}</li>
          </ul>
        </div>

        <div class="button-area">
          <div class="language-select" style="display: flex; align-items: center; padding-left: 1.75rem">
            <select id="language-select-form" :value="lang" @change="switchLanguage($event.target.value)">
              <option value="ko" :selected="lang == 'ko'">한국어</option>
              <option value="en" :selected="lang == 'en'">English(US)</option>
              <option value="cn" :selected="lang == 'cn'">中文(簡体)</option>
              <option value="hk" :selected="lang == 'hk'">中文(繁體)</option>
              <option value="jp" :selected="lang == 'jp'">日本語</option>
              <option value="vn" :selected="lang == 'vn'">Tiếng Việt</option>
            </select>
            <img
              src="/img/icon/header-language-blue.svg"
              style="display: inline-block; margin-right: 0.5rem; width: 22px; height: auto; vertical-align: top"
            />
            {{ lang == 'ko' || lang == 'kr' ? '한국어' : '' }}
            {{ lang == 'en' ? 'English(US)' : '' }}
            {{ lang == 'cn' ? '中文(簡体)' : '' }}
            {{ lang == 'hk' ? '中文(繁體)' : '' }}
            {{ lang == 'jp' ? '日本語' : '' }}
            {{ lang == 'vn' ? 'Tiếng Việt' : '' }}
            <i class="fa fa-angle-down" style="-webkit-text-stroke: #0091ff"></i>
          </div>
        </div>
        <!-- <div class="nav-area">
          <nav>
            <button @click="onClickLogin">
              {{ $__t("로그인") }}
            </button>
          </nav>
        </div> -->
      </div>
    </div>

    <div id="main-intro-section2" class="section">
      <div class="container">
        <div class="header">
          <div class="image-area">
            <progressive-img :blur="30" :ratio="1" src="/img/logo-white-new.svg" />
          </div>

          <div class="gnb-area">
            <ul>
              <li @click="onNavigate('Home')">{{ $__t('고객') }}</li>
              <li class="test-test" @click="onNavigate('HostLanding')">{{ $__t('호스트') }}</li>
              <li @click="downloadFile()">{{ $__t('서비스 소개서') }}</li>
            </ul>
          </div>

          <div class="button-area">
            <div class="language-select" style="display: flex; align-items: center; padding-left: 1.75rem">
              <select id="language-select-form" :value="lang" @change="switchLanguage($event.target.value)">
                <option value="ko" :selected="lang == 'ko'">한국어</option>
                <option value="en" :selected="lang == 'en'">English(US)</option>
                <option value="cn" :selected="lang == 'cn'">中文(簡体)</option>
                <option value="hk" :selected="lang == 'hk'">中文(繁體)</option>
                <option value="jp" :selected="lang == 'jp'">日本語</option>
                <option value="vn" :selected="lang == 'vn'">Tiếng Việt</option>
              </select>

              <img
                src="/img/icon/header-language-white.svg"
                style="display: inline-block; margin-right: 0.5rem; width: 22px; height: auto; vertical-align: top"
              />
              {{ lang == 'ko' || lang == 'kr' ? '한국어' : '' }}
              {{ lang == 'en' ? 'English(US)' : '' }}
              {{ lang == 'cn' ? '中文(簡体)' : '' }}
              {{ lang == 'hk' ? '中文(繁體)' : '' }}
              {{ lang == 'jp' ? '日本語' : '' }}
              {{ lang == 'vn' ? 'Tiếng Việt' : '' }}
              <i class="fa fa-angle-down" style="-webkit-text-stroke: #fff"></i>
            </div>
          </div>

          <!-- <div class="nav-area">
            <nav>
              <button @click="onClickLogin">
                {{ $__t("로그인") }}
              </button>
            </nav>
          </div> -->
        </div>

        <div class="body page-section__trigger-section--top">
          <div class="section-container">
            <div class="title-area">
              <p>상점의 빈 공간을 공유해 추가 수익을 창출하세요.</p>
              <div class="button-area">
                <a href="https://bwc.lugstay.com/kntojg">
                  <img src="/img/google-play-button.png" />
                </a>
                <a href="https://apps.apple.com/kr/app/lugstay/id1472429427">
                  <img src="/img/app-store-button.png" />
                </a>
              </div>
            </div>
            <div class="button-area"></div>
          </div>
          <!-- <progressive-background src="/img/hostlanding-background-1.png"> </progressive-background> -->
        </div>
      </div>
    </div>

    <div class="page-section page-section__intro-section">
      <div class="section-container">
        <div class="text-area">
          <p class="primary">
            물품을 보관할 수 있는
            <span class="underline-skewed"><span class="text">별도의 공간</span></span
            >만 있다면 어느 곳이든 가능합니다.
          </p>
          <p class="secondary">
            <span class="underline-skewed"><strong class="text">전국 5,000개</strong></span>
            이상의 상점과 함께하고 있어요.
          </p>
        </div>
      </div>
    </div>

    <div class="page-section page-section__advantage-section">
      <div class="section-container">
        <div class="title-area">
          <p>럭스테이 호스트라면 누구나 누릴 수 있어요!</p>
        </div>
        <div class="content-area">
          <ul>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img :blur="30" :ratio="1" src="/img/host-advantage-revenue.png" srcset="/img/host-advantage-revenue@2x.png 400w" />
                </div>
                <div class="card-title">물품 보관 수익</div>
                <div class="card-description">
                  유휴 공간을 활용해 추가적인<br />
                  부수익을 얻을 수 있어요
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img :blur="30" :ratio="1" src="/img/host-advantage-promote.png" srcset="/img/host-advantage-promote@2x.png 400w" />
                </div>
                <div class="card-title">홍보효과</div>
                <div class="card-description">
                  매장 노출을 통해<br />
                  우리 매장을 알릴 수 있어요.
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img :blur="30" :ratio="1" src="/img/host-advantage-attract.png" srcset="/img/host-advantage-attract@2x.png 400w" />
                </div>
                <div class="card-title">고객 유치</div>
                <div class="card-description">
                  물품 보관 고객을 매장 이용<br />
                  고객으로 만들 기회를 가질 수 있어요.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="content-area"></div>
      </div>
    </div>

    <div class="page-section page-section__service-section">
      <div class="section-container">
        <div class="title-area">
          <p>
            럭스테이 앱에 표현되는<br />
            나의 매장을 확인해 보세요.
          </p>
        </div>
        <div class="content-area">
          <ui-tab-image :items="serviceItems" />
        </div>
      </div>
    </div>

    <div class="page-section page-section__review-section">
      <div class="section-container">
        <div class="title-area">
          <p>럭스테이 호스트 분들의 후기를 담았습니다.</p>
        </div>
        <div class="content-area">
          <ui-card-image :items="reviewItems" :pager="true" />
        </div>
      </div>
    </div>

    <div class="page-section page-section__procedure-section">
      <div class="section-container">
        <div class="title-area">
          <p>호스트 신청과정</p>
        </div>
        <div class="content-area">
          <ul>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"><span class="text">Step.01</span></span>
                </div>
                <div class="card-title">고객센터 문의</div>
                <div class="card-description">
                  <p>
                    호스트 가입<br />
                    문의하기
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"><span class="text">Step.02</span></span>
                </div>
                <div class="card-title">호스트 가입 완료</div>
                <div class="card-description">
                  <p>
                    고객센터에서<br />
                    호스트 가입 처리
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"><span class="text">Step.03</span></span>
                </div>
                <div class="card-title">공간 등록하기</div>
                <div class="card-description">
                  <p>
                    럭스테이 앱<br />
                    > 호스트 페이지 에서<br />
                    공간 등록하기
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"><span class="text">Step.04</span></span>
                </div>
                <div class="card-title">승인 완료</div>
                <div class="card-description">
                  <p>최종 등록 완료</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="button-area">
          <p @click="onClockOpenChannelTalk()">내 상점공간 등록하기</p>
        </div>
      </div>
    </div>

    <div class="page-section page-section__faq-section">
      <div class="section-container">
        <div class="title-area">
          <p>자주 묻는 질문</p>
        </div>
        <div class="content-area content-area2">
          <ul>
            <li class="faq-item">
              <ui-accordion header="럭스테이는 어떤 서비스인가요?">
                <p>
                  럭스테이는 상점 내 남는 공간을 활용해서 고객들의 물품을 보관해주고<br />
                  물품보관 수수료를 창출할 수 있는 유휴공간 활용 서비스입니다.<br />
                  별도로 락커를 설치하거나, 넓은 자체 공간을 확보할 필요가 없으며<br />
                  외부인이 접근이 어려운 보안이 보장되는 장소에 물품을 보관해주시면 됩니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="번화가가 아닌 상점에서도 가능한가요?">
                <p>사업자 등록이 되어있는 상점이나 사무실이라면 어디든 럭스테이의 가맹점이 될 수 있습니다.</p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="비용책정과 정산은 어떻게 진행되나요?">
                <p>
                  사용한 시간만큼 금액이 계산되어 고객이 사전에 입력한 카드정보로 자동으로 결제됩니다.<br />
                  고객에게 직접 물품보관료 결제를 진행하실 필요가 없습니다.<br />
                  정산은 한달에 한 번 진행되며, 매달 정산을 진행하지 않아도 금액은 자동 누적됩니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="운영 시간에 변동이 있을 경우는 어떻게 하나요?">
                <p>럭스테이 어플을 통해 호스트가 직접 운영 시간과 일자를 변경할 수 있습니다.</p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="보관 물품의 분실이나 파손시 보상절차가 궁금합니다.">
                <p>럭스테이 자체 가입 보험을 통해 최대 100만원까지 보상됩니다.</p>
              </ui-accordion>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="page-section page-section__trigger-section--bottom">
      <div class="section-container">
        <div class="title-area">
          <p>
            지금 호스트 신청하고,<br />
            더 많은 고객을 만나보세요.
          </p>
        </div>
        <div class="button-area">
          <!-- <ui-solid-button v-button color="brightblue" size="little-large" display="inline" @click="applyHost('bottom')">
            <button type="button">호스트 등록하기</button>
          </ui-solid-button> -->
        </div>
      </div>
      <progressive-background src="/img/hostlanding-background-2.png"> </progressive-background>
    </div>

    <div id="main-contact-section" class="section">
      <div class="container">
        <div class="body">
          <div style="text-align: left">
            {{ $__t('비즈니스 제휴/협업에 관해서\n문의사항이 있으신가요?') }}
          </div>
          <div>
            <span @click="onContact()">
              {{ $__t('제휴/협업 문의') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <site-footer v-if="footerVisible" />
  </div>
</template>

<script>
import UiAccordion from '@/components/modules/Accordion/Body.vue';
import UiCardImage from '@/components/modules/CardImage/Body.vue';
import UiTabImage from '@/components/modules/TabImage/Body.vue';
import SiteFooter from '@/components/outline/Footer';

import HostApplyForm from '@/components/includes/HostApplyForm.vue';
import loadScript from 'load-script';
import { Language } from '@/modules/language/index.js';

export default {
  name: 'HostLanding',

  data() {
    return {
      lang: localStorage.getItem('lugstay_language') || 'ko',
      scrollDepth: {
        30: false,
        50: false,
        70: false,
        90: false
      },

      reviewItems: [
        {
          img: '/img/host-image-total-beauty.png',
          header: '매장의 여유 공간을 효율적으로 활용해서 물품 보관도 하고<br/> 수익도 얻을 수 있겠다는 생각이 들었습니다.',
          body: '유휴 공간을 활용해 나름 쏠쏠하게 부가수익을 올렸고, 물품 보관을 하러 오시는 고객에게 우리 살롱을 홍보하고 고객을 유치할 수도 있었어요. 이벤트를 위해 꽃다발과 케이크를 맡기신 손님이 계셨는데 이렇게도 럭스테이를 활용할 수 있구나 하고 배웠습니다.',
          label: '토탈뷰티 살롱 MDB네일'
        },
        {
          img: '/img/host-image-villa-de-murir.png',
          header: '추가적인 홍보 비용 없이<br/> 새로운 고객을 꾸준히 늘릴 수 있었습니다.',
          body: '물품을 보관하러 오셨다가 저희 빌라 드 뮤리의 고객이 되어주시기도 해요. 럭스테이를 통해 무료로 매장 홍보 효과를 보고 잠재적 수요도 확충할 수 있는 것이지요. 별도의 번거로운 수고 없이 럭스테이로 추가 수익 쌓는 헤택이 큰 장점이라고 할 수 있습니다.',
          label: '빌라드 뮤리'
        },
        {
          img: '/img/host-image-convenience-store.png',
          header: '매장 내 조그만 창고가 있어서 그 공간에 물품을 보관하면 수익도 생기고, 방문객이 편의점 물품을 구매할 것이라는 생각이 들었습니다.',
          body: '아무래도 편의점이 밤늦게까지 운영하다보니 시간이 애매해서 못맡기던 고객들이 맡기러 오고,방문한 김에 물품을 사가기도 합니다.',
          label: '편의점 호스트'
        },
        {
          img: '/img/host-image-cafe.png',
          header: '캐리어를 들고 오셨던 고객 분이 짐보관이 되는 매장이라는 것을 알고 맡긴 적이 있어요.',
          body: '관광지 근처 커피숍이다 보니 종종 짐을 맡아달라는 고객이 있는데, 공식적으로 맡아줄 수 있는 서비스가 있으니, 응대가 수월해지고 수익도 창출할 수 있어 좋았습니다. 고객 편의도 증진시켜줄 수 있어 매장 홍보에도 효과가 있는 것 같아요.',
          label: '카페 호스트'
        }
      ],

      serviceItems: [
        {
          img: '/img/host-service-map.png',
          tab: '지도',
          text: '상점의 실 주소를 바탕으로 럭스테이 지도 위에 상점의 위치가 노출됩니다. 매력적인 사진으로 고객의 관심을 사로잡을 수 있습니다.'
        },
        {
          img: '/img/host-service-store-single.png',
          tab: '상점상세',
          text: '상점 운영 시간 / 시설 현황 정보를 제공합니다. 냉동보관, 탈의실 등 고객에게 추가적으로 제공할 수 있는 서비스를 설정할 수 있습니다.'
        },
        {
          img: '/img/host-service-reservation.png',
          tab: '예약확인',
          text: '고객의 물품 보관 현황을 각 단계별로 손쉽게 확인할 수 있습니다.'
        }
      ]
    };
  },

  computed: {
    isLogin() {
      return !!this.$store.state?.auth?.gid;
    }
  },

  created() {
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('scroll', this.trackScrollDepth);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
    window.removeEventListener('scroll', this.toggleFloatedHeader, {
      passive: true
    });
  },

  async mounted() {
    await this.$nextTick();
    this.toggleFloatedHeader();

    window.addEventListener('scroll', this.toggleFloatedHeader, {
      passive: true
    });

    this.$analytics.logEvent('host registration page view'); //amplitude
    this.$analytics.tracking.call(this, 'host', 'pageview', 'host_registration_pageview'); //ga
    this.$analytics.pageView(); //kakao
    this.$analytics.cnv('etc');
    this.$analytics.tracking.call(this, null, 'conversion', null, {
      send_to: `${this.$store.state.config.get('googleAnalytics')?.conversionId}/LbmRCPH2gf8CEICEzvIC`
    });
  },

  methods: {
    toggleFloatedHeader: _.debounce(function () {
      const mainIntroSectionHeader = $('#main-intro-section2 > .container > .header');

      const mainIntroSectionHeaderHeight = mainIntroSectionHeader.offset().top + mainIntroSectionHeader.outerHeight();
      const scrollTop = $(window).scrollTop();
      const floatedHeaderHeight = $('#float-header', this.$el).outerHeight();

      const toggleFloatedHeader = mainIntroSectionHeaderHeight - floatedHeaderHeight - 38 <= scrollTop;

      if (toggleFloatedHeader === true) {
        if (this.isFloatedHeaderShown) return;

        $('#float-header.header', this.$el).addClass('show');
        this.isFloatedHeaderShown = true;
      } else {
        if (!this.isFloatedHeaderShown) return;

        $('#float-header.header', this.$el).removeClass('show');
        this.isFloatedHeaderShown = false;
      }
    }, 50),
    switchLanguage(locale) {
      this.$store.commit('loading/SET_TRUE');

      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
      }, 1000);

      this.$store.commit('SET_LANGUAGE', locale);

      const languageSet = {
        ko: 'ko',
        en: 'En',
        hk: 'ch_traditional',
        cn: 'ch_Simplified Chinese',
        jp: 'jp',
        vn: 'vietnamese',
        system: 'system'
      };

      this.$analytics.logEvent('language change', { language: languageSet[locale] });

      Language.setLanguage(locale);

      this.lang = locale;
    },
    onClickLogin() {
      location.href = 'https://app.lugstay.com';
    },

    onNavigate(e) {
      this.$router.push({ name: e });
    },

    downloadFile() {
      const url = 'https://cdn.u-hoo.com/uploaded/럭스테이(Lugstay)_서비스소개서.pdf';
      window.open(url);
    },

    onContact() {
      window.location.href = 'mailto:biz@lugstay.com';
    },
    onClockOpenChannelTalk() {
      var channelTalkUrl = 'https://lugstay.channel.io/home';
      window.open(channelTalkUrl, '_blank');
    },
    footerVisible() {
      if (this.wni.isNative) return false;

      if (this.$route.meta.footerType === 'none') {
        return false;
      }

      if (this.$route.meta.headerType === 'more-depth' || this.$route.params.headerType === 'more-depth') {
        return false;
      }

      return this.$route.meta && this.$route.meta.hideFooter !== true;
    },
    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    async applyHost(location) {
      this.$analytics.logEvent('host registration click', {
        location: location
      });
      this.$analytics.logTrack('track', 'Lead');
      this.$analytics.participation();
      this.$analytics.tracking.call(this, 'host', 'click', 'host_registration_click'); //ga

      window.history.pushState({}, null, '');

      // this.openRef('host-apply-modal');

      window.location.href = this.$store.state.config.get('hostURL');

      // if (this.isLogin) {
      //   this.$store.dispatch('auth/redirect', {
      //     redirect: this.redirect,
      //     queryString: this.queryString,
      //     query: { redirect_uri: `${this.$store.state.config.get('hostURL')}/login/redirect`, with_token: true },
      //     callback: this.callback
      //   });
      // } else {
      //   this.$router
      //     .push({ name: 'AuthRegisterTerms', query: { redirect_uri: `${this.$store.state.config.get('hostURL')}/login/redirect`, with_token: true } })
      //     .catch((e) => {
      //       // this.$store.commit('alert/ADD_ITEM', {
      //       //   message: e,
      //       //   status: 'error'
      //       // });
      //     });
      // }

      // loadScript('https://embed.typeform.com/embed.js', (error, script) => {
      //   if (error) {
      //     this.$store.commit('alert/ADD_ITEM', {
      //       message: error,
      //       status: 'error'
      //     });
      //     return;
      //   }

      //   // const url = "https://form.typeform.com/to/osPr0hX5";
      //   const url = 'https://form.typeform.com/to/Og2VkBhi';
      //   const options = {
      //     mode: 'popup',
      //     open: 'time',
      //     openValue: 10,
      //     hideScrollbars: true,
      //     onReady: this.onTypeformReady,
      //     onClose: this.onCloseTypeform,
      //     onSubmit: this.onSubmitTypeform
      //   };
      //   try {
      //     window.typeformEmbed.makePopup(url, options);
      //     this.loading = true;
      //   } catch (e) {}
      // });
    },

    openTypeForm() {
      if (this.wni.isNative) {
        this.wni.execute('wnPopupOpen', {
          url: 'https://www.facebook.com/LugStay.official/'
        });
      }
    },

    onTypeformReady() {
      this.$analytics.logEvent('start host registration typeform');
      this.$analytics.logTrack('track', 'TypeformFirstInteraction');
      this.$analytics.cnv('register');
      this.$analytics.tracking.call(this, 'host', 'pageview', 'start_host_registration_typeform'); //ga
      this.$analytics.tracking.call(this, null, 'conversion', null, {
        send_to: `${this.$store.state.config.get('googleAnalytics')?.conversionId}/SEXeCJqk1f4CEICEzvIC`
      });
      window.ChannelTalk.hide();
      this.loading = false;
      this.$channelTalk.hide();
    },

    onCloseTypeform() {
      this.loading = false;
      window.history.back();
      this.$channelTalk.show();
    },

    onSubmitTypeform() {
      this.$analytics.logEvent('submit host registration typeform');
      this.$analytics.logTrack('track', 'TypeformSubmit');
      this.$analytics.cnv('signUp');
      this.$analytics.tracking.call(this, 'host', 'click', 'submit_host_registration_typeform'); //ga
      this.$analytics.signUp();
      this.$channelTalk.show();
      this.$analytics.tracking.call(this, null, 'conversion', null, {
        send_to: `${this.$store.state.config.get('googleAnalytics')?.conversionId}/iJJ_CLvOx_0CEICEzvIC`
      });
    },

    onPopState() {
      window.location.reload();
    },

    onClickCall() {
      return (location.href = 'tel:+82-218779727');
    },

    onClick(target) {
      switch (target) {
        case 'facebook':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.facebook.com/LugStay.official/'
            });
          } else {
            window.open('https://www.facebook.com/LugStay.official/');
          }
          break;
        case 'instagram':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.instagram.com/lugstay.official/'
            });
          } else {
            window.open('https://www.instagram.com/lugstay.official/');
          }
          break;
        case 'naver_blog':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://blog.naver.com/lugstay'
            });
          } else {
            window.open('https://blog.naver.com/lugstay');
          }
          break;
        case 'youtube': {
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured'
            });
          } else {
            window.open('https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured');
          }
          break;
        }
      }
    }
  },

  components: {
    UiAccordion,
    UiCardImage,
    UiTabImage,
    SiteFooter,

    HostApplyForm
  }
};
</script>

<style scoped lang="scss">
#host-apply-modal::v-deep {
  .lug-guide-modal-container {
    width: 90%;
    max-width: $max-content-width;
  }
}
</style>
